import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

const Page404 = ({ data }) => {

  const { t } = useTranslation();
  return (
  <Layout>
    <div className="section is-medium is-gradient">
      <div className="container" style={{ position: "relative", zIndex: 5 }}>
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column is-4">
            <div className="content is-large">
              <h1 className="title is-1">{<>{t("contact.titolo")}</>}</h1>
            </div>
            <div className="content is-large" style={{ paddingBottom: "50px" }}>
              <h2 className="title is-4 is-white" style={{ lineHeight: 1.3 }}>
                {t("page404.pageTitle")}
              </h2>
            </div>
          </div>
          <div className="column is-1"></div>
          <div
            className="column is-4 is-gradient-bottom-right"
            style={{ padding: "50px" }}
          >
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default Page404
